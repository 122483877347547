











import { Vue, Component, Prop } from 'vue-property-decorator';

@Component({ })
class WivModal extends Vue {

    @Prop({ type: Boolean, default: false})
    private readonly isOpened!: boolean;

}

export default WivModal;

