
































































































import { Component, Vue } from "vue-property-decorator";
import { ValidationObserver, ValidationProvider } from "vee-validate";
import VsLoading from "@/components/Controls/vs-Loading.vue";
import VsAlert from "@/components/Layouts/vs-Alert.vue";
import axios from "axios";
interface ValidationObserver extends Vue {
    validate: () => Promise<boolean>;
}

@Component({
    components: { ValidationObserver, ValidationProvider, VsAlert, VsLoading }
})
class ContatoPage extends Vue {
    private form = {
        nome: "",
        telefone: "",
        email: "",
        mensagem: ""
    };
    private loading: boolean = false;

    private validated: boolean = false;

    private messageFocus: boolean = false;

    private POLITICA_PRIVACIDADE_URL = process.env.VUE_APP_POLITICA_PRIVACIDADE_URL;

    private submit(): void {
        if (!this.loading) {
            const form = this.$refs.form as ValidationObserver;
            form.$emit("validate");
            this.validated = true;
            form.validate().then(isValid => {
                if (isValid) {
                    this.loading = true;
                    this.$ga.event({
                        eventCategory: "ContactForm",
                        eventAction: "SubmitForm",
                        eventLabel: "EmailSubmited",
                        eventValue: 1
                    });

                    axios
                        .post("https://api.pilotar.app/api/email", this.form, {
                            headers: {
                                ApiKey: "41CD01E7-CDA9-4A20-8617-8A5E49F47E83"
                            }
                        })
                        .then(() => {
                            (this.$refs.alert as VsAlert).showAlert(
                                "E-mail enviado",
                                "Seu e-mail foi enviado com sucesso.",
                                null,
                                "email"
                            );
                            this.form.nome = "";
                            this.form.telefone = "";
                            this.form.email = "";
                            this.form.mensagem = "";
                        })
                        .catch(() => {
                            (this.$refs.alert as VsAlert).showAlert(
                                "Opss",
                                "Não foi possível enviar seu e-mail, por favor, tente novamente",
                                null,
                                "alert"
                            );
                        })
                        .finally(() => {
                            this.loading = false;
                        });
                }
            });
        }
    }
}

export default ContatoPage;
