export default class RegisterModel {

    constructor(
        public nome: string = '', 
        public email: string = '', 
        public telefone: string = '',
        public senha: string = '',
        public confirmarSenha: string = '',
    ) { }

}