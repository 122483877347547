

































import { Component, Vue } from "vue-property-decorator";

@Component({})
class SimuladorPage extends Vue {
    private index: number = 1;

    private swipeLeft(): void {
        this.changeIndex(1);
    }

    private swipeRight(): void {
        this.changeIndex(-1);
    }
    private changeIndex(value: number): void {
        if (this.index + value < 1) {
            this.index = 4;
        } else if (this.index + value > 4) {
            this.index = 1;
        } else {
            this.index += value;
        }
    }
}

export default SimuladorPage;
