








import { Component, Vue, Prop } from 'vue-property-decorator';

@Component({ })
export default class VsIcon extends Vue {

    @Prop({ type: String, required: true })
    private readonly icon!: string;

    @Prop({ type: String, default: '20px'})
    private readonly size!: string;

    @Prop({ type: String, default: 'red'})
    private readonly color!: string;
}
