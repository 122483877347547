





































import { Component, Vue, Watch } from "vue-property-decorator";

import HomePage from "@/views/HomePage.vue";
import ParceirosPage from "@/views/ParceirosPage.vue";
import VantagensPage from "@/views/VantagensPage.vue";
import BeneficiosPage from "@/views/BeneficiosPage.vue";
import SimuladoTeoricoPage from "@/views/SimuladoTeoricoPage.vue";
import AvaliarPage from "@/views/AvaliarPage.vue";
import ChatPage from "@/views/ChatPage.vue";
import BiometriaPage from "@/views/BiometriaPage.vue";
import SimuladorPage from "@/views/SimuladorPage.vue";
import CnhPage from "@/views/CnhPage.vue";
import ContatoPage from "@/views/ContatoPage.vue";

import animateScrollTo, { IOptions } from "animated-scroll-to";
import EventBus from "@/store/eventBus";
import PilotarMenuMobile from "@/components/Layouts/pilotar-MenuMobile.vue";

@Component({
    components: {
        ParceirosPage,
        HomePage,
        VantagensPage,
        BeneficiosPage,
        SimuladoTeoricoPage,
        AvaliarPage,
        ChatPage,
        BiometriaPage,
        SimuladorPage,
        CnhPage,
        ContatoPage,
        PilotarMenuMobile
    }
})
class App extends Vue {
    // ============== Ciclo de vida =============
    private mounted(): void {
        EventBus.scrollPosition = window.pageYOffset + window.innerHeight;
        document.addEventListener(
            "scroll",
            () => {
                EventBus.scrollDirection =
                    window.pageYOffset + window.innerHeight >=
                    EventBus.scrollPosition
                        ? "down"
                        : "top";
                EventBus.scrollPosition =
                    window.pageYOffset + window.innerHeight;
                EventBus.scrollY = window.scrollY;

                this.scrollPosition = window.pageYOffset;
            },
            true
        );
    }

    // ========== Variáveis reativas ============

    private scrollPosition: number = 0;
    private isInCnhPage: boolean = false;
    private isInPageEnd: boolean = false;

    // ========= Variáveis computadas ==========
    private get busScrollPosition(): number {
        return EventBus.scrollPosition;
    }

    private get scrollDirection(): string {
        return EventBus.scrollDirection;
    }

    // ============== Observadores ==============
    @Watch("scrollPosition")
    private onScrollPositionChange(): void {
        const cnhOffsetTop = ((this.$refs.cnhPage as Vue).$el as HTMLElement)
            .offsetTop;
        const contatoOffsetTop = ((this.$refs.contatoPage as Vue)
            .$el as HTMLElement).offsetTop;

        this.isInCnhPage =
            this.busScrollPosition > cnhOffsetTop + 65 &&
            this.busScrollPosition < contatoOffsetTop + 65;
        this.isInPageEnd = this.scrollPosition >= contatoOffsetTop - 15;
    }

    // =============== Métodos =================
    private goToBegin(): void {
        // const options: IOptions = {
        //     maxDuration: 2000,
        //     speed: 1000
        // };
        // animateScrollTo(0, options);
        window.scrollTo(0, 0);
    }

    private openMenu(): void {
        (this.$refs.menu as PilotarMenuMobile).toogleButton();
    }

    private navigate(page: string): void {
        (this.$refs.menu as PilotarMenuMobile).scroll(page);
    }
}

export default App;
