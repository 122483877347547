








import { Component, Vue, Prop } from 'vue-property-decorator';

@Component({ })
class VsLoading extends Vue {
    @Prop({ type: String, default: 'white' })
    private color!: string;
}

export default VsLoading;
