










import { Component, Vue, Prop } from 'vue-property-decorator';

@Component({ })
class ParallaxView extends Vue {

    private mounted(): void {
        document.addEventListener('scroll', this.onScroll, true);
    }

    @Prop({ type: Number, default: 15})
    private readonly amount!: number;

    @Prop({ type: String })
    private readonly background?: string;

    @Prop({ type: Boolean, default: false })
    private readonly ease!: boolean;

    private translate: number = 0;
    private screenSize: number = window.innerHeight;

    private onScroll(): void {
        const position = (window.pageYOffset / (this.$refs.paralax as HTMLElement).offsetTop - 1);
        if(this.ease) {
            if(position < 0) {
                this.translate = position * (this.amount * 2);
            }
            else {
                this.translate = position * (this.amount * 10);
            }
        }
        else {
            this.translate = position * (this.amount * 10);
        }
    }
}

export default ParallaxView;
