










































































































































import { Component, Vue, Watch } from "vue-property-decorator";
import EventBus from "@/store/eventBus";

interface VueNumber extends Vue {
    play(): void;
}

@Component({})
class ParceirosPage extends Vue {
    private mounted(): void {
        this.windowResize();
        const el = this.$refs.text as HTMLElement;
        this.textOpacity =
            (window.pageYOffset + window.innerHeight - el.offsetTop) /
            (el.offsetHeight * 0.5);
        document.addEventListener("resize", this.windowResize, true);
    }

    private offsetTop: number = 0;
    private textOpacity: number = 0;

    private windowResize(): void {
        this.offsetTop = (this.$refs.text as HTMLElement).offsetTop;
    }

    private theFormat(number: number): string {
        return number.toFixed(3);
    }

    private get scrollPosition(): number {
        return EventBus.scrollPosition;
    }

    @Watch("scrollPosition")
    private onScrollPositionChanged(): void {
        if (
            this.scrollPosition >=
            (this.$refs.parceirosPage as HTMLElement).offsetTop + 50
        ) {
            (this.$refs.number1 as VueNumber).play();
            (this.$refs.number2 as VueNumber).play();
            (this.$refs.number3 as VueNumber).play();
            (this.$refs.number4 as VueNumber).play();
        }

        const el = this.$refs.text as HTMLElement;
        this.textOpacity =
            (window.pageYOffset + window.innerHeight - el.offsetTop) /
            (el.offsetHeight * 0.5);
    }
}

export default ParceirosPage;
