















































import { Component, Vue, Watch } from "vue-property-decorator";
import EventBus from "@/store/eventBus";

@Component({})
class SimuladoTeoricoPage extends Vue {
    private mounted(): void {
        window.addEventListener("resize", this.onResize, false);
        this.onResize();
    }

    private offsetTop: number = 0;

    private onResize(): void {
        this.offsetTop = (this.$refs.simulado as HTMLElement).offsetTop;
    }

    private get scrollPosition(): number {
        return EventBus.scrollPosition;
    }

    @Watch("scrollPosition")
    private onScrollPositionChanged(): void {
        this.onResize();
    }

    private get showGreen(): boolean {
        return this.scrollPosition >= this.offsetTop + window.innerHeight;
    }
}

export default SimuladoTeoricoPage;
