


























import { Component, Vue } from 'vue-property-decorator';
import VsModal from './vs-Modal.vue';
import VsIcon from '../Controls/vs-Icon.vue';

export interface VsOption {
    title: string;
    action: (args?: any) => void;
    primary?: boolean;
}

@Component({ components: { VsModal, VsIcon } })
class VsAlert extends Vue {
    
    private options: VsOption[] | null = null;

    private icon: string = 'alert';
    private title: string = '';
    private message: string = '';
    private isOpened: boolean = false;

    public showAlert(title: string, message: string, options: VsOption[] | null = null, icon: string = 'alert') {
        this.options = options;
        this.icon = icon;
        this.title = title;
        this.message = message;

        this.isOpened = true;
    }

    private loadAction(action: () => void) {
        this.isOpened = false;
        setTimeout(() => {
            action();
        }, 300);
    }
}

export default VsAlert;
