import { VueConstructor } from 'vue';

// Layouts
import VsParallaxView from '@/components/Layouts/vs-ParallaxView.vue';
import VsModal from '@/components/Layouts/vs-Modal.vue';

// Controls
import VsIcon from '@/components/Controls/vs-Icon.vue';
import VsLottie from '@/components/Controls/vs-Lottie.vue';
import VsInput from '@/components/Controls/vs-Input.vue';

const install = (Vue: VueConstructor) => {
    Vue.component('vs-parallax-view', VsParallaxView);

    // Layouts
    Vue.component('vs-modal', VsModal);

    // Controls
    Vue.component('vs-icon', VsIcon);
    Vue.component('vs-lottie', VsLottie);
    Vue.component('vs-input', VsInput);
};

export default install;