
































































































































































































import { Component, Vue, Watch } from "vue-property-decorator";
import { ValidationObserver } from "vee-validate";

import LoginModel from "@/models/LoginModel";
import RegisterModel from "@/models/RegisterModel";
import animateScrollTo from "animated-scroll-to";

import EventBus from "@/store/eventBus";
@Component({ components: { ValidationObserver } })
class HomePage extends Vue {
    // ========== Variáveis reativas ============
    private navOpened: boolean = false;
    private cadastroModalOpened: boolean = false;
    private modalIndex: number = 0;
    private loginForm: LoginModel = new LoginModel();
    private registerForm: RegisterModel = new RegisterModel();

    // ========= Variáveis computadas ==========
    private get modalPageClass(): string {
        switch (this.modalIndex) {
            case 0:
                return "--bem-vindo";
            case 1:
                return "--informar-dados";
            case 2:
                return "--informacoes";
            case 3:
                return "--senha";
            default:
                return "";
        }
    }
    private get isFixed(): boolean {
        return this.scrollPosition > 400;
    }
    private get scrollDirection(): string {
        return EventBus.scrollDirection;
    }
    private get scrollPosition(): number {
        return EventBus.scrollY;
    }

    // =============== Métodos =================
    private scroll(page: string): void {
        const element: Element = document.querySelector(page) as Element;
        animateScrollTo(element);
    }
    private openMenu(): void {
        this.navOpened = true;
        this.$emit("openMenu");
    }
}

export default HomePage;
