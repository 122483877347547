import { VueConstructor } from 'vue';

export default function install(Vue: VueConstructor) {
    const sizeChanged = () => {
        Vue.prototype.$device.type = deviceType(window.innerWidth);
    };

    const deviceType = (windowSize: number ): string => {
        if(windowSize < 600) {
            return 'mobile';
        }
        else if(windowSize >= 600 && windowSize <= 1023) {
            return 'tablet';
        }
        else {
            return 'desktop';
        }
    };

    Vue.prototype.$device = Vue.observable({ type: deviceType(window.innerWidth)});
    window.addEventListener('resize', sizeChanged, true);
}